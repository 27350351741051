<template>
  <div class="navigation-bar">
    <div class="head">
      <div :class="['left', { isIOS_: 'iosPadding' }]" v-show="isLeft">
        <img @click="handleBack" class="icon" :src="returnArrow" />
      </div>
      <div :class="['title', { isIOS_: 'iosPadding' }]" :style="{ color: color }">
        {{ title }}
      </div>
      <div class="right" v-show="isRight">
        <img
          class="icon"
          src="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/memberBenefits/more.png"
          @click="visibility = true"
        />
      </div>
    </div>
    <div class="cont" v-show="visibility" @tap="visibility = false">
      <div class="list">
        <div
          class="list-item"
          v-for="(item, index) in moreData"
          :key="index"
          @click="handleJump(item)"
        >
          <img class="icon" :src="item.icon" />
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "#694126",
    },
    isLeft: {
      type: Boolean,
      default: false,
    },
    isRight: {
      type: Boolean,
      default: false,
    },
    returnArrow: {
      type: String,
      default:
        "https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/memberBenefits/left.png",
    },
    moreData: {
      type: Array,
      default: () => [
        {
          name: "领取记录",
          url: "/tao/memberBenefits/record/collection/collection",
          icon:
            "https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/memberBenefits/iconGiftBox.png",
        },
        {
          name: "扣费记录",
          url: "/tao/memberBenefits/record/deduction/deduction",
          icon:
            "https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/memberBenefits/iconMoney.png",
        },
      ],
    },
  },
  data() {
    return {
      visibility: false,
      isIOS_: false,
    };
  },
  created() {
    this.isIOS_ = this.isIOS();
    // if (this.isIOS()) {
    //   alert(1);
    // } else {
    //   alert(this.isIOS());
    // }
  },
  methods: {
    isIOS() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    },
    handleJump(item) {
      this.visibility = false;
      this.$emit("handleJump", item);
    },
    handleBack() {
      this.$emit("handleBack");
    },
  },
};
</script>

<style lang="scss" scoped>
.iosPadding {
  padding-top: constant(safe-area-inset-top) !important;
  padding-top: env(safe-area-inset-top) !important;
}
.navigation-bar {
  position: relative;
  padding-top: 36px;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 88px;
    padding: 0 36px 0;

    .left {
      // padding-top: 36px;

      .icon {
        width: 38px;
      }
    }

    .title {
      // padding-top: 36px;
      font-size: 32px;
      color: #694126;
      flex: 1;
      text-align: center;
    }

    .right {
      .icon {
        width: 30px;
      }
    }
  }

  .icon {
    vertical-align: middle;
  }
  .cont {
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .list {
    background: url("https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/memberBenefits/moreBG.png")
      center center/contain no-repeat;
    width: 259px;
    height: 201px;
    right: 16px;
    font-size: 26px;
    color: #333333;
    border-radius: 20px;
    text-align: center;
    float: right;
    padding: 50px 0;
    transform: translate(-20px, 100px);
    // transform: translate(-12px, 36px);

    &-item {
      &:not(:first-child) {
        padding-top: 40px;
      }
    }
    .icon {
      width: 34px;
      margin-right: 24px;
    }
  }
}
</style>
