<template>
  <div class="base-loading">
    <div class="base-loading-content">
      <div>加载中</div>
      <img
        src="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/loading.gif"
        width="40%"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.base-loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 1, 0, 0.6);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  &-content {
    height: 30%;
    width: 60%;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
