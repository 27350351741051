<template>
  <div class="not-opened">
    <base-navigation-bar title="随心会员" />
    <img
      src="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/memberBenefits/hdgz.png"
      class="btn"
      @click="showAgreement('/tao/memberBenefits/notOpened/agreement/rules')"
    />
    <div class="content">
      <van-button class="submit" @click="handleSubmit">¥19.9/月开通随心会员</van-button>
      <div class="checked">
        <img
          v-if="isTag"
          src="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/memberBenefits/tag.png"
          class="tag"
          mode="widthFix"
        />
        <div class="checked-cont">
          <img
            v-show="!checked"
            @click="checked = true"
            src="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/memberBenefits/checkedFalse.png"
            class="icon"
            mode="widthFix"
          />
          <img
            v-show="checked"
            @click="checked = false"
            src="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/memberBenefits/checkedTrue.png"
            class="icon"
            mode="widthFix"
          />
          <span>我已阅读并同意</span>
          <span
            class="book"
            @click="showAgreement('/tao/memberBenefits/notOpened/agreement/agreement')"
            >《会员服务协议》</span
          >
          <div>
            <span>和</span>
            <span
              class="book"
              @click="showAgreement('/tao/memberBenefits/notOpened/agreement/renew')"
              >《自动续费协议》</span
            >
          </div>
        </div>
      </div>
    </div>
    <base-loading v-if="loading" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { appLogin, alipayPay } from "@/api/tao.js";
import BaseLoading from "@/components/BaseLoading/loading";
import BaseNavigationBar from "@/components/BaseNavigationBar/BaseNavigationBar.vue";
import { isWeixin } from "@/utils/common";

export default {
  components: {
    BaseLoading,
    BaseNavigationBar,
  },
  data() {
    return {
      loading: false,
      isTag: false,
      checked: false,
      phone: "",
      appToken: "",
    };
  },
  created() {
    const { token = "", phone = "" } = this.$route.query;
    this.phone = phone;
    this.appToken = token;
    this.handleLogin();
  },
  computed: {
    ...mapGetters(["member_user_info"]),
  },
  watch: {
    member_user_info: {
      handler(value) {
        if (value.available_vip && value.available_vip.includes("视听会员")) {
          this.$router.push("/tao/memberBenefits/opened/opened");
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapMutations(["memberBenefits/SET_DATA"]),
    ...mapActions(["memberBenefits/getUserInfo"]),
    async handleLogin() {
      const { code = 0, phone_number = "", head_img = "", token = "" } = await appLogin({
        app_token: this.appToken,
      });
      this.code = code;
      if (code == 0) {
        this["memberBenefits/SET_DATA"]({
          member_phone_number: phone_number,
          member_head_img: head_img,
          member_token: token,
        });
        this["memberBenefits/getUserInfo"]();
      }
    },
    async handleSubmit() {
      if (!this.checked) {
        this.isTag = true;
        let that = this;
        setTimeout(() => {
          that.isTag = false;
        }, 2000);
        return false;
      }
      this.isTag = false;
      if (isWeixin()) {
        this.$toast({
          title: "请在浏览器打开进行订购",
          icon: "none",
          duration: 2000,
        });
        return;
      }
      this.loading = true;
      const res = await alipayPay({
        item_name: "视听会员",
        phone_number: this.phone,
      });
      this.loading = false;
      if (res.code == 0) {
        location.href = `alipays://platformapi/startapp?appId=20000067&url=${encodeURIComponent(
          res.sign_str
        )}`;
      } else {
        this.$toast.fail(res.message);
      }
    },
    showAgreement(url) {
      this.$router.push({ path: url });
    },
  },
};
</script>

<style lang="scss" scoped>
.not-opened {
  min-height: 1509px;
  height: 100%;
  background: #feedcf
    url("https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/memberBenefits/notOpenedBG2.png")
    center top/contain no-repeat;
  position: relative;

  .btn {
    width: 149px;
    position: absolute;
    right: 0;
    top: 150px;
  }

  .content {
    padding-top: 1126px;
    text-align: center;

    .submit {
      width: 650px;
      height: 88px;
      line-height: 88px;
      background: linear-gradient(335deg, #ff7c00 0%, #ff5000 100%);
      border-radius: 44px;
      font-size: 32px;
      color: #ffffff;
      border: none;
    }

    .checked {
      position: relative;

      .tag {
        width: 146px;
        position: absolute;
        top: -38px;
        left: 70px;
        pointer-events: none;
      }

      &-cont {
        color: #694126;
        font-size: 24px;
        padding-top: 20px;
        text-align: center;

        .icon {
          vertical-align: middle;
          width: 23px;
          // height: 24upx;
          margin-right: 10px;
        }

        span {
          vertical-align: middle;
        }

        .book {
          color: #ff5000;
        }
      }
    }
  }
}
</style>
